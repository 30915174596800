<template>
  <div id="app" class=".backToTop" >
    <el-container>
      <el-header>
        <div class="logo">
          <img src="../../assets/img/logo.png" alt/>
        </div>
        <el-menu
            :default-active="$route.path"
            class="el-menu-demo menu_class"
            mode="horizontal"
            @select="handleSelect"
            text-color="#000000"
            :router="router">
<!--          <el-menu-item :index="item.index" v-for="item in items" :key="item.index" @click="saveNavState(item.index)">-->
<!--            {{item.title}}-->
<!--          </el-menu-item>-->
          <el-menu-item index="/dashboard">首页</el-menu-item>
          <el-menu-item index="/news">新闻资讯</el-menu-item>
          <el-menu-item index="/product">产品中心</el-menu-item>
          <el-menu-item index="/case">案例展示</el-menu-item>
          <el-menu-item index="/aboutsy">关于三阅</el-menu-item>
<!--                    <el-submenu index="/goin">-->
<!--                      <template #title index="/goin">关于三阅</template>-->
<!--                      <el-menu-item index="2-1">item one</el-menu-item>-->
<!--                      <el-menu-item index="2-2">item two</el-menu-item>-->
<!--                      <el-menu-item index="2-3">item three</el-menu-item>-->
<!--                    </el-submenu>-->
          <el-menu-item index="/contactus">联系我们</el-menu-item>
        </el-menu>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <div class="footer" v-show="true">
        <div class="footer-content">
          <div class="content-nav">
            <ul>
              <li><a>首页</a></li>
              <li><a href="#" @click.prevent="toPage('productcenter')">产品中心</a></li>
              <li><a href="#" @click.prevent="toPage('caseshow')">案例展示</a></li>
              <li><a href="#" @click.prevent="toPage('news')">新闻资讯</a></li>
            </ul>
            <ul>
              <li><a>了解我们</a></li>
              <li><a href="#" @click.prevent="toPage('News')">新闻资讯</a></li>
              <li><a href="#" @click.prevent="toPage('Productcenter')">产品中心</a></li>
              <li><a href="#" @click.prevent="toPage('Caseshow')">案例展示</a></li>
            </ul>
            <ul>
              <li><a >关于三阅</a></li>
              <li><a href="#" @click.prevent="toPage('CompanyProfile')">公司简介</a></li>
              <li><a href="#" @click.prevent="toPage('corporateculture')">企业文化</a></li>
              <li><a href="#" @click.prevent="toPage('Honorsqualifications')">荣誉资质</a></li>
            </ul>
          </div>
          <div style="width: 120px;text-align: center;padding-top: 15px">
            <img src="../../assets/img/gongzhonghao.jpg" alt/>
            <span style="font-size: 13px;color: white">关注微信公众号</span>
          </div>
        </div>
        <div class="copyright">
          <span>Copyright © 2021 Sanyue IT Co.,Ltd. All Rights Reserved. <a href="http://beian.miit.gov.cn/" target="_blank">浙ICP备20026602号-1</a></span>
        </div>
      </div>
      <!-- 在线客服 -->
      <div class="online" v-show="toTopisShow">
        <div>
          <ul>
            <!--          <li><a><img src="./images/Icon/OnlineConsulting.png">在线咨询</a></li>-->
            <!--          <li><a><img src="./images/Icon/telephone.png">免费通话</a></li>-->
            <!--          <li><a><img src="./images/Icon/QRcode.png">微信联系</a></li>-->
            <!--          <li @click="toTop"><a><img src="./images/Icon/backTop.png">返回顶部</a></li>   -->

            <!--            <li><a><img src="./images/Icon/messageWhite.png"></a></li>-->
            <li @mouseenter="onlineChuFa('phone')" @mouseleave="onlineJieChu('phone')"><a><img
                src="../../assets/icon/phoneWhite.png">免费通话</a></li>
            <li @mouseenter="onlineChuFa('gongzhonghao')" @mouseleave="onlineJieChu('gongzhonghao')"><a><img
                src="../../assets/icon/QRCodeWhite.png">微信联系</a></li>
            <li @mouseenter="onlineChuFa('message')" @mouseleave="onlineJieChu('message')"><a><img
                src="../../assets/icon/messageWhite.png">在线留言</a></li>
            <li @mouseenter="onlineChuFa('toTop')" @click="toTop"><a><img src="../../assets/icon/toTopWhite.png">返回顶部</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- 在线客服内容弹出框 -->
      <div class="online_content" v-show="online_content_div">
        <div v-show="online_content_phone" @mouseenter="onlineChuFa('phone')" @mouseleave="onlineJieChu('phone')">
          <ul>
            <li>业务资讯</li>
            <li>联系电话：15267043556</li>
          </ul>
        </div>
        <div v-show="online_content_gongzhonghao" @mouseenter="onlineChuFa('gongzhonghao')"
             @mouseleave="onlineJieChu('gongzhonghao')">
          <div id="qrimg"><img src="../../assets/img/weixin.png"></div>
          <p>微信资讯客服</p>
        </div>
        <div v-show="online_content_message" @mouseenter="onlineChuFa('message')" @mouseleave="onlineJieChu('message')">
          <div id="message">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <el-form-item prop="name">
                <el-input
                    placeholder="姓名"
                    v-model="ruleForm.name"
                    clearable>
                </el-input>
              </el-form-item>
              <el-form-item prop="contact">
                <el-input
                    placeholder="联系方式"
                    v-model="ruleForm.contact"
                    clearable>
                </el-input>
              </el-form-item>
              <el-form-item prop="content">
                <el-input
                    type="textarea"
                    placeholder="留言内容"
                    resize="none"
                    :autosize="{ minRows: 4, maxRows: 4}"
                    v-model="ruleForm.content">
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="width: 258px" @click="submitForm('ruleForm')">提交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

    </el-container>
  </div>
</template>
<script>

export default {
  data() {
    return {
      // windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
      // windowHeight: document.documentElement.clientHeight,   //实时屏幕高度

      itemWidth: 0,
      itemHeight: 0,
      itemFontSize: 0,

      router: true,
      textarea: '',
      toTopisShow: false,
      scroll: '',
      online_content_phone: false,
      online_content_gongzhonghao: false,
      online_content_message: false,
      online_content_div:false,
      //在线留言表单
      ruleForm: {
        name: '',
        contact: '',
        content: '',
      },
      rules: {
        name: [
          {required: true,message:'请输入名称',trigger:'blur'}
        ],
        contact: [
          {required: true,message:'请输入联系方式',trigger:'blur'}
        ],
        content: [
          {required: true,message:'请输入留言内容',trigger:'blur'}
        ],
      },
      items: [
        {
          index: '/dashboard',
          title: '首页'
        },
        {
          index: '/news',
          title: '新闻资讯'
        },
        {
          index: '/product',
          title: '产品中心'
        },
        {
          index: '/case',
          title: '案例展示'
        },
        {
          index: '/aboutus',
          title: '关于三阅'
        },
        {
          index: '/contactus',
          title: '联系我们'
        },
      ]
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    // document.documentElement.scrollTop = 80

    // var that = this;
    // // <!--把window.onresize事件挂在到mounted函数上-->
    // window.onresize = () => {
    //   return (() => {
    //     window.fullHeight = document.documentElement.clientHeight;
    //     window.fullWidth = document.documentElement.clientWidth;
    //     that.windowHeight = window.fullHeight;  // 高
    //     that.windowWidth = window.fullWidth; // 宽
    //   })
    // };
  },
  // watch: {
  //   windowHeight (val) {
  //     // let that = this;
  //     // console.log("实时屏幕高度：",val, that.windowHeight );
  //   },
  //   windowWidth (val) {
  //
  //   }
  // },
  created() {
  },
  methods: {
    toPage(string){
      let path=this.$route.path
      sessionStorage.setItem('anchorId',string);
      if(string === 'productcenter'){
        this.$router.push("/dashboard")
        if(path === '/dashboard'){
          document.documentElement.scrollTop =window.innerHeight
        }
      }else if(string === 'caseshow'){
        this.$router.push("/dashboard")
        if(path === '/dashboard'){
          document.documentElement.scrollTop =window.innerHeight+787
        }
      }else if(string === 'news'){
        this.$router.push("/dashboard")
        if(path === '/dashboard'){
          document.documentElement.scrollTop =window.innerHeight+787+755
        }
      }else if(string === 'News'){
        this.$router.push("/news")
      }else if(string === 'Productcenter'){
        this.$router.push("/product")
      }else if(string === 'Caseshow'){
        this.$router.push("/case")
      }else if(string === 'CompanyProfile'){
        this.$router.push("/aboutsy")
        if(path === '/aboutsy'){
          document.documentElement.scrollTop =0
        }
      }else if(string === 'corporateculture'){
        this.$router.push("/aboutsy")
        if(path === '/aboutsy'){
          document.documentElement.scrollTop =180+80+464
        }
      }else if(string === 'Honorsqualifications'){
        this.$router.push("/aboutsy")
        if(path === '/aboutsy'){
          document.documentElement.scrollTop =180+80+464+575
        }

      }
    },
    handleSelect(index){

      if (index === '/dashboard') {
        this.toTopisShow = false
      } else {
        this.toTopisShow = true
      }
    },
    onlineChuFa(string) {
      if (string === 'phone') {
        this.online_content_div = true
        this.online_content_phone = true
        this.online_content_gongzhonghao = false
        this.online_content_message = false
      } else if (string === 'gongzhonghao') {
        this.online_content_div = true
        this.online_content_gongzhonghao = true
        this.online_content_phone = false
        this.online_content_message = false
      } else if (string === 'message') {
        this.online_content_div = true
        this.online_content_gongzhonghao = false
        this.online_content_phone = false
        this.online_content_message = true
      } else {
        this.online_content_gongzhonghao = false
        this.online_content_phone = false
        this.online_content_message = false
      }
    },
    onlineJieChu(string) {
      if (string === 'phone') {
        this.online_content_div = false

        this.online_content_phone = false
      } else if (string === 'gongzhonghao') {
        this.online_content_div = false

        this.online_content_gongzhonghao = false;
      } else {
        this.online_content_div = false
        this.online_content_message = false;
      }
    },
    toTop() {
      // document.documentElement.scrollTop = 0;

      let l = document.documentElement.scrollTop;
      let s = l / 450 * 10;  //路程/时间=s *25每隔25ms秒 动的路程
      let st = setInterval(function () {
        l -= s;
        if (l <= 0) {
          l = 0; //当l<=0时，设置l=0
          clearInterval(st);
        }
        document.documentElement.scrollTop = l;
      }, 10)
    },
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop
      var path = this.$route.path
      if (this.scroll > 400 || path!== '/dashboard') {
        this.toTopisShow = true
      } else {
        this.toTopisShow = false
      }
    },
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios({
            url:  this.localpath + 'officialweb/createStayNote',
            method: 'post',
            data: JSON.stringify(this.ruleForm),
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                }
          }).then(res => {
            if (res.data.code === 0) {
              this.$notify({
                title: '提交成功',
                message: '请耐心等待回复'
              });
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }

      });
    },

  },

};
</script>
<style lang="scss">
li {
  list-style: none;
}

a {
  text-decoration: none; /* 去除默认的下划线 */
  color: #000; /* 去除默认的颜色和点击后变化的颜色 */
}

* {
  margin: 0;
  padding: 0;
}
.el-form-item {
  margin-bottom: 15px !important;
}
/* 在全局CSS里引用: */
/* 滚动条宽度 */
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 3px;
}

.hide-timeout {
  width: 105px;
  text-align: center;
}

html,
body {
  height: 100%;
}




#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.el-menu-item {
  font-size: 16px !important;
  height: 70px !important;
  line-height: 70px !important;
  text-align: center !important;
  border-bottom: 0 !important;
}
.el-menu--horizontal>.el-menu-item {

}
.el-menu-item:hover, .el-menu-item:active {
  border-bottom: 0 !important;
  background-color: #3ec5c6 !important;
  color: #ffffff !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 0 !important;
  background-color: #3ec5c6 !important;
  color: #ffffff !important;
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 70px !important;
  line-height: 70px !important;
}


.el-menu--popup {
  min-width: 0 !important;
  width: 100px !important;
  margin-top: 0 !important;
}

.el-submenu__title {
  padding: 0 !important;
}

.el-header {
  display: flex;
  //justify-content: space-between;
  align-content: center;
  //width: 1240px;
  width: 1100px;
  height: 70px !important;
  margin: 0 auto;
  background-color: #fff;

  .logo {
    //position: absolute;
    //left: 0;
    height: 70px;
    margin-right: 65px;

    img {
      height: 70px;
      //line-height: 60px;
      object-fit: cover
    }
  }
}

.el-main {
  padding: 0 !important;
}

.footer {
  width: 100%;
  //height: 286px;
  overflow: hidden;
  position: relative;
  background-color: #1b1b1b;;

  &-content {
    width: 1100px;
    margin: 0 auto;
    padding-top: 45px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-around;

    .content-nav {
      display: flex;
      justify-content: space-around;

      ul {
        width: 170px;

        li:nth-child(1) {
          //width: 430px;
          //display: flex;
          //flex-direction: column;
          height: 20px;
          line-height: 20px;
          margin-bottom: 14px;
          //justify-content: center;
          //align-items: flex-start;
          a {
            font-size: 16px;
            color: #fff;

          }
        }

        li {

          height: 30px;
          line-height: 30px;
          overflow: hidden;

          a {
            color: #a8a8a8;
            font-weight: 300;
            font-size: 13px;
          }
          a:hover,a:active{
            color: #fff;
          }
        }
      }


    }

    img {
      width: 110px;
      height: 110px;
    }
  }

  .copyright {
    width: 100%;
    text-align: center;

    border-style: solid;
    border-color: #333333;
    border-top-width: 2px;
    background-color: #0b0b0b;;

    span {
      font-size: 14px;
      color: #999999;
      line-height: 43px;
      a {
        font-size: 14px;
        color: #999999;
        line-height: 43px;
      }
    }
  }
}

.right_choose {
  position: fixed;
  float: right;
}

/*在线客服*/
.online {
  position: fixed;
  //margin: auto;
  top: calc(50% - 90px);
  right: 20px;
  width: 60px;
  height: 240px;
  z-index: 999;
  color: #999;

  ul {
    li {
      width: 60px;
      height: 64px;
      list-style: none;
      padding: 0px;
      margin: 0px 0px 1px;
      font-size: 8px;
      color: white;
      text-align: center;
      background-color: #27a8e1;

      a {
        color: white;
        //img {
        //  padding-top: 7px;
        //  padding-left: 15px;
        //  padding-right: 15px;
        //  width: 30px;
        //  height: 30px;
        //}
        img {
          padding: 10px 15px 0 15px;
          width: 25px;
          height: 25px;
        }
      }

    }

    //li:nth-child(4) {
    //  margin-top: 10px;
    //}

    li:hover {
      background-color: #1781bd;
    }

  }

}

/*在线客服*/
.online_content {
  position: fixed;
  //margin: auto;
  top: calc(50% - 90px);
  right: 80px;
  width: 300px;
  height: 240px;
  z-index: 999;
  color: #999;

  > div:nth-child(1) {
    position: absolute;
    left: 100px;
    width: 198px;
    height: 80px;
    background-color: #ffffff;
    border: 1px solid #ccc;

    ul {
      margin-top: 10px;

      li {
        padding: 0 20px;
        font-size: 14px;
        font-weight: bold;
        color: #165aa8;
        line-height: 2.2em;
      }
    }
  }

  > div:nth-child(2) {
    position: absolute;
    left: 100px;
    background-color: #ffffff;
    border: 1px solid rgb(220, 220, 220);
    width: 198px;
    height: 215px;
    border: 1px solid #ccc;

    #qrimg {
      padding-top: 20px;
      padding-left: 20px;

      img {
        width: 160px;
        height: 160px;
      }
    }

    p {
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      color: #165aa8;
    }
  }

  > div:nth-child(3) {
    position: absolute;
    background-color: #ffffff;
    width: 258px;
    height: 255px;
    padding: 20px;
    border: 1px solid #ccc;
  }
}
</style>
